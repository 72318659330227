import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default (props) => (
  <Layout pageTitle="MESSAGE" location={props.location} showSocialMedia={['top', 'bottom']}>
    <SEO title="MESSAGE" />
    <p>MESSAGE (Model for Energy Supply Strategy Alternatives and their General Environmental Impact) is an energy modelling tool. The models represent energy conversion and utilization processes of the energy system (or its part) and their environmental impacts and are used for development of medium to long term strategies. The time scope is limited by the uncertainties associated with future technological development. The energy system dynamics is modelled by multi period approach. The representation of the energy system in the model is based on a network concept. The activities and relationships of the energy system are described as an oriented graph, depicting the energy chain starting from extraction or supply of primary energy, passing through the several energy conversion processes (e.g. electricity generation, transmission and distribution) in order to satisfy the demand for final energy in the industry, household, transportation and other branches of the economy. Using the notation of oriented graph, the links of the graph represent technologies or transportation and allocation processes of energy, while the nodes represent energy forms (like electricity, oil, and gas).</p>
    <p>Within the REEEM project, MESSAGE is used for a case study on “Regional energy security of the Baltic region and Finland”. The mathematical model MESSAGE-BALFIN developed for this purpose represents peculiarities of energy systems of Baltic States and Finland in much bigger details than many European-level models. The main attention is paid to power and district heating systems.</p>
    <p>Detailed spatial representation in the model is ensured by individual modeling of the power system of each country coupled with several district heating systems within each country. Representation of existing and possible new electricity transmission lines between analysed countries and lines with third countries gives an opportunity to have a realistic electricity exchange and efficient utilisation of different energy generating technologies in each country. The detailed operating regimes of energy systems are ensured by representation of typical seasons, working and holiday days, which are divided into small time intervals. The base year is 2010 and the time horizon ends in 2050 that is split in 5-year time steps.</p>
    <p><a href="https://openenergy-platform.org/factsheets/models/145/">MESSAGE model FACT SHEET</a></p>
  </Layout>
)
